<template>
  <v-dialog
    v-model="dialog"
    width="80%"
    @click:outside="$emit('update:dialog', false)"
  >
    <v-card>
      <v-card-title class="primary--text px-4 py-1 text-body-1">
        {{
          doubleClickData && doubleClickData.invoice
            ? $t("generalEvaluation.invoiceReport")
            : doubleClickData && doubleClickData.receipt
            ? $t("generalEvaluation.receiptReport")
            : doubleClickData && doubleClickData.purchase
            ? $t("generalEvaluation.purchaseReport")
            : doubleClickData && doubleClickData === "debt"
            ? $t("generalEvaluation.customerDebts")
            : doubleClickData && doubleClickData === "future_check"
            ? $t("generalEvaluation.customerFutureChecks")
            : doubleClickData && doubleClickData === "supplier_debt"
            ? $t("generalEvaluation.supplierDebts")
            : doubleClickData && doubleClickData === "supplier_check"
            ? $t("generalEvaluation.supplierChecks")
            : doubleClickData && doubleClickData === "delivery_note"
            ? $t("generalEvaluation.deliveryNotes")
            : doubleClickData && doubleClickData === "proforma_invoice"
            ? $t("generalEvaluation.proformas")
            : doubleClickData && doubleClickData === "order_sum"
            ? $t("generalEvaluation.orderInProduction")
            : doubleClickData && doubleClickData === "supplier_order"
            ? $t("generalEvaluation.supplierOrders")
            : ""
        }}
        <v-spacer />
        <v-col cols="3" class="text-end d-flex">
          <v-text-field
            :full-width="false"
            :dense="true"
            hide-details="auto"
            :autofocus="true"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('common.search')"
            single-line
          />
        </v-col>
        <v-btn icon @click="$emit('update:dialog', false); page = 1">
          <v-icon color="primary darken-1">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-row no-gutters>
        <v-col cols="12">
          <v-card class="mx-auto" outlined>
            <v-card-text class="text-h5 pa-0">
              <v-data-table
                :height="
                  doubleClickDataList.allRecords &&
                  doubleClickDataList.allRecords.length > 0
                    ? '400'
                    : ''
                "
                :style="{
                  maxHeight: !doubleClickDataList.allRecords ? '790px' : '',
                }"
                :dense="true"
                class="custom-table mb-0 ma-2 row-pointer"
                :headers="
                  doubleClickData &&
                  (doubleClickData.invoice || doubleClickData === 'debt')
                    ? invoiceHeaders
                    : doubleClickData &&
                      (doubleClickData.receipt ||
                        doubleClickData === 'future_check')
                    ? receiptHeaders
                    : doubleClickData &&
                      (doubleClickData.purchase ||
                        doubleClickData === 'supplier_debt')
                    ? purchaseHeaders
                    : doubleClickData && doubleClickData === 'supplier_check'
                    ? supplierCheckHeaders
                    : doubleClickData && doubleClickData === 'delivery_note'
                    ? incomeDeliveryNoteHeaders
                    : doubleClickData && doubleClickData === 'proforma_invoice'
                    ? incomeProformaHeaders
                    : doubleClickData && doubleClickData === 'order_sum'
                    ? incomeOrderHeaders
                    : doubleClickData && doubleClickData === 'supplier_order'
                    ? supplierOrderHeaders
                    : ''
                "
                :items="doubleClickDataList.allRecords"
                style="word-break: break-word"
                item-class="py-4"
                item-key="randomKey"
                fixed-header
                :items-per-page="limitData"
                :page.sync="page"
                :search="search"
                @dblclick:row="doubleClick"
                @pagination="newPagination"
                @page-count="pageCount = $event"
                must-sort
              >
                <template v-slot:[`item.id`]="{ item }">
                  <span :ref="`row-${item.id}`">{{ item.id }}</span>
                </template>
                <template v-slot:[`item.grand_total`]="{ item }">
                  <span>{{ numberWithCommas(item.grand_total) }}</span>
                </template>
                <template v-slot:[`item.unit_price`]="{ item }">
                  <span>{{ numberWithCommas(item.unit_price) }}</span>
                </template>
                <template v-slot:[`item.total_unit_price`]="{ item }">
                  <span>{{ numberWithCommas(item.total_unit_price) }}</span>
                </template>
                <template v-slot:[`item.remaining_quantity_amount`]="{ item }">
                  <span>{{
                    numberWithCommas(item.remaining_quantity_amount)
                  }}</span>
                </template>
                <template v-slot:[`item.total`]="{ item }">
                  <span>{{ numberWithCommas(item.total) }}</span>
                </template>
                <template v-slot:[`item.after_discount`]="{ item }">
                  <span>{{ numberWithCommas(item.after_discount) }}</span>
                </template>
                <template v-slot:[`item.balance`]="{ item }">
                  <span>{{ numberWithCommas(item.balance) }}</span>
                </template>
                <template v-slot:[`item.vat`]="{ item }">
                  <span>{{ !item.vat ? null : item.vat }}</span>
                </template>
                <template v-slot:[`item.discount`]="{ item }">
                  <span>{{ !item.discount ? null : item.discount }}</span>
                </template>
                <template v-slot:[`footer.prepend`]>
                  <v-row class="d-flex">
                    <v-col cols="6">
                      <span class="pl-3 pt-2"
                        >{{ $t("common.numberOfTotalRows") }}
                        {{
                          doubleClickDataList.allRecords &&
                          doubleClickDataList.allRecords.length
                            ? numberOfRows
                            : 0
                        }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <span class="text-subtitle-2"
                        >{{ $t("receipt.totalPerReport") }}:
                        {{
                          doubleClickDataList &&
                          doubleClickDataList.totalAmmount
                            ? numberWithCommas(doubleClickDataList.totalAmmount)
                            : 0
                        }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:[`footer.page-text`]>
                  <v-row class="d-flex">
                    <v-col class="m-0">
                      <v-container>
                        <v-pagination
                          :total-visible="5"
                          v-model="page"
                          :length="
                            doubleClickDataList.allRecords ? pageCount : 0
                          "
                        ></v-pagination>
                      </v-container>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:no-data>
                  <v-card-text class="text-caption text-center">{{
                    $t("common.noDataFound")
                  }}</v-card-text>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { formatDateDDMMYYYY } from "@/utils";

export default {
  name: "DoubleClickReport",
  props: ["dialog", "doubleClickData", "selectedYear", "withoutVat"],
  data() {
    return {
      limitData: this.limit,
      limit: this.pageChange?.limit ? Number(this.pageChange?.limit) : 10,
      page: this.pageChange?.page ? Number(this.pageChange?.page) : 1,
      pagination: null,
      search: "",
    };
  },
  computed: {
    ...mapGetters({
      doubleClickDataList: "generalEvaluation/doubleClickDataList",
      pageChange: "generalEvaluation/pageChange",
      accessRight: "accessRight",
    }),
    invoiceHeaders() {
      return [
        {
          text: this.$t("documentSeries.docNumber"),
          value: "invoice_document_number",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("documentSeries.series"),
          value: "document_number_series",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.field.invoiceDate"),
          value: "invoice_date",
          width: "160px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.field.customerName"),
          value: "customer_name",
          width: "180px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.field.customerId"),
          value: "customer_id",
          width: "100px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.field.status"),
          value: "status",
          width: "100px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.field.title"),
          value: "title",
          width: "140px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.field.grandTotal"),
          value: "grand_total",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
      ];
    },
    receiptHeaders() {
      return [
        {
          text: this.$t("documentSeries.docNumber"),
          value: "recipt_document_number",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("documentSeries.series"),
          value: "document_number_series",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("receipt.field.customerId"),
          value: "customer_id",
          width: "100px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("receipt.field.customerName"),
          value: "customer_name",
          width: "180px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("receipt.field.receiptDate"),
          value: "receipt_date",
          width: "160px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("receipt.field.grandTotal"),
          value: "grand_total",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
      ];
    },
    purchaseHeaders() {
      return [
        {
          text: this.$t("supplierInvoice.supplierInvoiceNo"),
          value: "invoice_no",
          width: "170px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("purchaseOrder.supplier"),
          value: "supplier_id",
          width: "120px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("purchaseOrder.supplierName"),
          value: "supplier_name",
          width: "180px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("supplierInvoice.invoiceDate"),
          value: "invoice_date",
          width: "160px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("order.field.grandTotal"),
          value: "grand_total",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
      ];
    },
    supplierCheckHeaders() {
      return [
        {
          text: this.$t("supplier.id"),
          value: "id",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("generalEvaluation.supplierReceiptDate"),
          value: "supplier_receipt_id",
          width: "180px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("generalEvaluation.paymentTermsId"),
          value: "payment_terms_id",
          width: "140px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("deposit.chequeNumber"),
          value: "cheque_no",
          width: "180px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("deposit.bank"),
          value: "bank",
          width: "160px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("deposit.branch"),
          value: "branch",
          width: "160px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("deposit.amount"),
          value: "amount",
          width: "140px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("trialBalance.accountNo"),
          value: "account_no",
          width: "180px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("generalEvaluation.supplierPaymentDate"),
          value: "supplier_payment_date",
          width: "160px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("receipt.field.receiptYear"),
          value: "supplier_receipt_payment_year",
          width: "100px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("receipt.field.createdAt"),
          value: "created_at",
          width: "180px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("receipt.field.updatedAt"),
          value: "updated_at",
          width: "180px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
      ];
    },
    incomeDeliveryNoteHeaders() {
      return [
        {
          text: this.$t("supplier.id"),
          value: "id",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.field.customerName"),
          value: "customer_name",
          width: "180px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.field.deliveryDate"),
          value: "delivery_date",
          width: "160px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.unitPrice"),
          value: "unit_price",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("quotation.quantity"),
          value: "quantity",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("quotation.suppliedQty"),
          value: "supplied",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("quotation.remainingQty"),
          value: "remaining_quantity",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("generalEvaluation.remainigQtyAmount"),
          value: "remaining_quantity_amount",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
      ];
    },
    incomeProformaHeaders() {
      return [
        {
          text: this.$t("supplier.id"),
          value: "proforma_invoice_id",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.field.customerName"),
          value: "customer_name",
          width: "180px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.unitPrice"),
          value: "unit_price",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("quotation.quantity"),
          value: "quantity",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("quotation.suppliedQty"),
          value: "supplied",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("quotation.remainingQty"),
          value: "remaining_quantity",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("generalEvaluation.totalUnitPrice"),
          value: "total_unit_price",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
      ];
    },
    incomeOrderHeaders() {
      return [
        {
          text: this.$t("supplier.id"),
          value: "order_id",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.field.customerName"),
          value: "customer_name",
          width: "180px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.unitPrice"),
          value: "unit_price",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("quotation.quantity"),
          value: "quantity",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("quotation.suppliedQty"),
          value: "supplied",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("generalEvaluation.totalUnitPrice"),
          value: "total_unit_price",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
      ];
    },
    supplierOrderHeaders() {
      return [
        {
          text: this.$t("supplier.id"),
          value: "purchase_order_id",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("supplier.supplierName"),
          value: "supplier_name",
          width: "180px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("invoice.unitPrice"),
          value: "unit_price",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("quotation.quantity"),
          value: "quantity",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("quotation.suppliedQty"),
          value: "supplied",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("generalEvaluation.totalUnitPrice"),
          value: "total_unit_price",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
      ];
    },
    monthData() {
      const months = {
        january: 1,
        february: 2,
        march: 3,
        april: 4,
        may: 5,
        june: 6,
        july: 7,
        august: 8,
        september: 9,
        october: 10,
        november: 11,
        december: 12,
      };
      const monthName = this.doubleClickData?.month?.toLowerCase();
      return months[monthName] || null;
    },
  },
  created() {
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
  },
  beforeDestroy() {
    this.$store.commit("generalEvaluation/SHOW_PAGE_CHANGE", {
      page: 1,
      limit: this.pagination.itemsPerPage,
    });
  },
  async mounted() {
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
    if (this.doubleClickData) {
      const monthNumber = this.monthData;
      if (this.withoutVat) {
        if (this.doubleClickData.invoice) {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&monthdata=${monthNumber}&type=mainInvoice&vat=${this.withoutVat}`
          );
        } else if (this.doubleClickData.receipt) {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&monthdata=${monthNumber}&type=mainReceipt&vat=${this.withoutVat}`
          );
        } else if (this.doubleClickData.purchase) {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&monthdata=${monthNumber}&type=mainPurchase&vat=${this.withoutVat}`
          );
        } else if (this.doubleClickData === "debt") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=customerObligationDebts&vat=${this.withoutVat}`
          );
        } else if (this.doubleClickData === "future_check") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=customerObligationFutureCheck&vat=${this.withoutVat}`
          );
        } else if (this.doubleClickData === "supplier_debt") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=supplierDebts&vat=${this.withoutVat}`
          );
        } else if (this.doubleClickData === "supplier_check") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=supplierFutureChecks&vat=${this.withoutVat}`
          );
        } else if (this.doubleClickData === "delivery_note") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=incomeforecastDeliveryNote&vat=${this.withoutVat}`
          );
        } else if (this.doubleClickData === "proforma_invoice") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=incomeforecastProformaInvoice&vat=${this.withoutVat}`
          );
        } else if (this.doubleClickData === "order_sum") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=incomeforecastOrder&vat=${this.withoutVat}`
          );
        } else if (this.doubleClickData === "supplier_order") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=incomeforecastSupplierOrder&vat=${this.withoutVat}`
          );
        }
      } else {
        if (this.doubleClickData.invoice) {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&monthdata=${monthNumber}&type=mainInvoice`
          );
        } else if (this.doubleClickData.receipt) {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&monthdata=${monthNumber}&type=mainReceipt`
          );
        } else if (this.doubleClickData.purchase) {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&monthdata=${monthNumber}&type=mainPurchase`
          );
        } else if (this.doubleClickData === "debt") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=customerObligationDebts`
          );
        } else if (this.doubleClickData === "future_check") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=customerObligationFutureCheck`
          );
        } else if (this.doubleClickData === "supplier_debt") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=supplierDebts`
          );
        } else if (this.doubleClickData === "supplier_check") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=supplierFutureChecks`
          );
        } else if (this.doubleClickData === "delivery_note") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=incomeforecastDeliveryNote`
          );
        } else if (this.doubleClickData === "proforma_invoice") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=incomeforecastProformaInvoice`
          );
        } else if (this.doubleClickData === "order_sum") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=incomeforecastOrder`
          );
        } else if (this.doubleClickData === "supplier_order") {
          await this.$store.dispatch(
            "generalEvaluation/GetDoubleClickData",
            `?year=${this.selectedYear}&type=incomeforecastSupplierOrder`
          );
        }
      }
    }
  },
  watch: {
    pagination: {
      handler(newVal) {
        if (this.pagination) {
          this.limit = newVal.itemsPerPage ? newVal.itemsPerPage : 10;
        }
      },
      deep: true,
    },
  },
  methods: {
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    numberWithCommas(x) {
      return x
        ? parseFloat(x)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : (0).toFixed(2);
    },
    newPagination(val) {
      this.pagination = val;
      this.numberOfRows = val.itemsLength;
      this.page = val.page
    },
    doubleClick(event, { item }) {
      if (item.type === "Invoice") {
        const path = "invoice/editInvoice/" + item.id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      } else if (item.type === "Receipt-Tax") {
        const path = "receiptTaxInvoice/editReceiptTaxInvoice/" + item.id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      } else if (item.type === "Credit-Invoice") {
        const path = "creditInvoice/editCreditInvoice/" + item.id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      } else if (item.type === "Receipt") {
        const path = "receipt/editReceipt/" + item.id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      } else if (item.type === "Supplier-Invoice") {
        const path = "supplierInvoice/editSupplierInvoice/" + item.id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      } else if (item.type === "Supplier-Credit-Invoice") {
        const path =
          "supplierCreditInvoice/editSupplierCreditInvoice/" + item.id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      } else if (item.type === "Supplier-Receipt-Payment") {
        const path = "supplierPayment/editSupplierPayment/" + item.id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      } else if (item.type === "Delivery") {
        const path = "delivery/editDelivery/" + item.id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      } else if (item.type === "Proforma") {
        const path = "proforma/editProforma/" + item.proforma_invoice_id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      } else if (item.type === "Order") {
        const path = "order/editOrder/" + item.order_id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      } else if (item.type === "Purchase-Order") {
        const path =
          "purchaseOrder/editPurchaseOrder/" + item.purchase_order_id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      } else {
        const path = "invoice/editInvoice/" + item.id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep .v-data-footer__pagination .row div {
  padding: 0px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 5px !important;
}
</style>
