<template>
  <v-dialog
    v-model="dialog"
    width="80%"
    @click:outside="$emit('update:dialog', false)"
  >
    <v-card>
      <v-card-title class="primary--text px-4 py-1 text-body-1">
        {{ $t("generalEvaluation.multiYearEvaluation") }}
        <v-spacer />
        <v-btn icon @click="$emit('update:dialog', false)">
          <v-icon color="primary darken-1">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0 px-4">
        <v-simple-table>
          <thead>
            <tr>
              <th>{{ $t("generalEvaluation.month") }}</th>
              <th>{{ selectedYear - 3 }}</th>
              <th>{{ selectedYear - 2 }}</th>
              <th>%</th>
              <th>{{ selectedYear - 1 }}</th>
              <th>%</th>
              <th>{{ selectedYear }}</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="month in months" :key="month">
              <td class="font-weight-medium">{{ $t(`generalEvaluation.monthList.${month}`) }}</td>
              <td>{{ getInvoiceValue(selectedYear - 3, month) }}</td>
              <td>{{ getInvoiceValue(selectedYear - 2, month) }}</td>
              <td>{{ numberWithCommasFloat(getPercentageValue(selectedYear - 2, month)) }}%</td>
              <td>{{ getInvoiceValue(selectedYear - 1, month) }}</td>
              <td>{{ numberWithCommasFloat(getPercentageValue(selectedYear - 1, month)) }}%</td>
              <td>{{ getInvoiceValue(selectedYear, month) }}</td>
              <td>{{ numberWithCommasFloat(getPercentageValue(selectedYear, month)) }}%</td>
            </tr>
            <tr>
              <td class="font-weight-medium">{{ $t('generalEvaluation.total') }}</td>
              <td class="font-weight-medium">{{ getTotalAccumulated(selectedYear - 3) }}</td>
              <td class="font-weight-medium">{{ getTotalAccumulated(selectedYear - 2) }}</td>
              <td class="font-weight-medium">{{ numberWithCommasFloat(getPercentageAccumulated(selectedYear - 2)) }}%</td>
              <td class="font-weight-medium">{{ getTotalAccumulated(selectedYear - 1) }}</td>
              <td class="font-weight-medium">{{ numberWithCommasFloat(getPercentageAccumulated(selectedYear - 1)) }}%</td>
              <td class="font-weight-medium">{{ getTotalAccumulated(selectedYear) }}</td>
              <td class="font-weight-medium">{{ numberWithCommasFloat(getPercentageAccumulated(selectedYear)) }}%</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MultiYearEvaluationDialog",
  props: ["dialog", "obligationDataList", "selectedYear"],
  computed: {
    ...mapGetters({
      locale: "locale",
    }),
    months() {
      return [
        "january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"
      ];
    }
  },
  methods: {
    getInvoiceValue(year, month) {
      const record = this.obligationDataList.find(data => data.year === year)?.records.find(record => record.month === month);
      return record ? this.numberWithCommas(record.invoice) : '0';
    },
    getPercentageValue(year, month) {
      const record = this.obligationDataList.find(data => data.year === year)?.records.find(record => record.month === month);
      return record && record.percentageChange !== null ? `${record.percentageChange}%` : '';
    },
    getTotalAccumulated(year) {
      const data = this.obligationDataList.find(data => data.year === year);
      return data ? this.numberWithCommas(data.totalAccumulated) : '0';
    },
    getPercentageAccumulated(year) {
      const data = this.obligationDataList.find(data => data.year === year);
      return data && data.finalAccumalted !== null ? `${data.finalAccumalted}%` : '';
    },
    numberWithCommas(x) {
      return x ? parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
    },
    numberWithCommasFloat(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (0).toFixed(2);
    },
  },
};
</script>
