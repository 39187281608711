<template>
  <div
    :class="className"
    :style="{height: height,width: width}"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import echarts from 'echarts';
import debounce from '@/utils';

require('echarts/theme/macarons'); // echarts theme

export default {
  name: 'OptionChart',
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '300px',
    },
    chartData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    chart: null,
  }),
  computed: {
    ...mapGetters({
      locale: 'locale',
    })
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      },
    },
  },
  mounted() {
    this.initChart();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener('resize', this.resizeHandler);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener('resize', this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    setOptions(option = {}) {
      this.chart.showLoading({text: '', color: '#224a7d'});
      this.chart.setOption({
        ...option,
        xAxis: { ...option.xAxis, inverse: this.locale === 'he' ? true : false},
        yAxis: { ...option.yAxis, position: this.locale === 'he' ? 'right' : 'left'},
        grid: {
          ...option.grid,
          left: this.locale === 'he' ? 35 : 75,
          right: this.locale === 'he' ? 75 : 35,
        }
      }, true);
      let self = this;
      setTimeout(function() {
        self.chart.hideLoading();
      }, 1000);
    },
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions(this.chartData);
    },
  },
};
</script>
<style lang="scss" scoped>
.headline ::v-deep{
  color: var(--v-primary-darken1) !important;
}
</style>
