<template>
  <div>
    <div v-if="this.$route.name === 'GeneralEvaluation'">
      <GeneralEvaluation />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import GeneralEvaluation from "@/views/companyApp/generalEvaluation/GeneralEvaluation.vue";

export default {
  name: "GeneralEvaluationParent",
  components: {
    GeneralEvaluation,
  },
};
</script>
